<template>
  <div class="dashboard">
    <b-modal
      v-for="document in documentsToAccept"
      :key="`document-acceptation-modal-${document.id}`"
      :title="`${$t('customer.dashboard.document-of-company')}: ${document.organization.shortName}`"
      :ok-only="document.required"
      :ok-title="$t('shared.btn.accepting')"
      :cancel-title="$t('shared.btn.rejecting')"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :visible="documentsToAccept.length > 0"
      :scrollable="true"
      @ok="acceptDocument(document.id)"
      @cancel="declineDocument(document.id)"
    >
      <div v-html="document.content" />
      <div class="text-right text-muted">
        <p>
          {{ $t('customer.dashboard.document-last-update') }}: <i>{{ transformDatetimeToHumanReadablePretty(document.updatedAt) }}</i>
        </p>
      </div>
    </b-modal>

    <b-row class="mt-1">

      <b-col
        cols="12"
        md="6"
        xl="5"
        offset-xl="1"
      >
        <h4 class="mb-1">
          {{ $t('customer.dashboard.past-events') }}
        </h4>

        <b-card
          v-for="event in pastCalendarEvents "
          :key="event.id"
          class="mb-1 cursor-pointer"
          no-body
          @click="$router.push({name: 'customer-meeting-view', params: {id: event.id}})"
        >

          <dashboard-meeting-details :event="event" />

        </b-card>

      </b-col>

      <b-col
        cols="12"
        md="6"
        xl="5"
      >
        <h4 class="mb-1">
          {{ $t('customer.dashboard.future-events') }}
        </h4>

        <b-card
          v-for="event in futureCalendarEvents "
          :key="event.id"
          class="mb-1 cursor-pointer"
          no-body
          @click="$router.push({name: 'customer-meeting-view', params: {id: event.id}})"
        >

          <dashboard-meeting-details :event="event" />

        </b-card>

      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import dashboardSetup from '@/views/customer/dashboard/dashboardSetup'
import DashboardMeetingDetails from '@/views/customer/dashboard/DashboardMeetingDetails'
import { transformDatetimeToHumanReadablePretty } from '../../../helpers/dateTimeHelper'

export default {
  components: {
    DashboardMeetingDetails,
    BRow,
    BCol,
    BCard,

  },
  methods: { transformDatetimeToHumanReadablePretty },
  setup: dashboardSetup,
}
</script>
