<template>
  <b-row>
    <b-col cols="9">

      <b-card-body>
        <b-card-title class="mb-0">
          {{ event.service.name }}
        </b-card-title>

        <b-card-text>
          <div v-if="event.subject">
            <i>{{ event.subject }}</i>
          </div>

          <div>
            <small>{{ $t('shared.educator') }}:
              <strong>
                {{ event.calendar.owner.lastName }}
                {{ event.calendar.owner.firstName }}
              </strong>
            </small>
          </div>

          <div v-if="me.roles.includes(role.guardian)">
            <small>{{ $tc('shared.plural.attendees-title', Object.values(event.calendarEventCustomers).length) }}:
              <strong>
                {{ Object.values(event.calendarEventCustomers).map(c => `${c.customer.lastName} ${c.customer.firstName}`).join(', ') }}
              </strong>
            </small>
          </div>

          <small>{{ eventDate }}</small>

          <div
            v-if="paymentStatus.total"
            class="mt-1"
          >
            <b-badge
              v-if="paymentStatus.paid === paymentStatus.total "
              variant="success"
            >
              {{ $t('meeting.attendees.status-paid') }}
            </b-badge>
            <b-badge
              v-else-if="paymentStatus.paid === 0"
              variant="danger"
            >
              {{ $t('meeting.attendees.status-not-paid') }}
            </b-badge>
            <b-badge
              v-else
              variant="warning"
            >
              {{ $t('meeting.attendees.status-partially-paid') }}
            </b-badge>
          </div>
        </b-card-text>
      </b-card-body>

    </b-col>

    <b-col cols="3">

      <!-- event calendar card -->
      <div class="meetup-header d-flex align-items-center align-bottom h-100 border-left">
        <div class="w-100 text-center">
          <h6 class="mb-0">
            {{ $t(`shared.months.${dayjs(event.startAt).month()}`).substring(0,3).toUpperCase() }}
          </h6>
          <h3 class="mb-0">
            {{ dayjs(event.startAt).format('D') }}
          </h3>
          <p class="mb-0 font-small-3 font-weight-bold">
            {{ dayjs(event.startAt).format('HH:mm') }}
          </p>
        </div>
      </div>
      <!--/ event calendar card -->

    </b-col>
  </b-row>
</template>
<script>
import {
  BBadge,
  BCardBody, BCardText, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import { capitalizeFirstLetter } from '@/helpers/helpers'
import role from '@/auth/role'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardText,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      role,
    }
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    day() {
      return dayjs(this.event.startAt)
    },
    dayTo() {
      return dayjs(this.event.endAt)
    },
    dayString() {
      return capitalizeFirstLetter(this.$t(`shared.days.long.${this.day.day()}`))
    },
    month() {
      return this.$t(`shared.months-for-full-date.${this.day.month()}`)
    },
    eventDate() {
      return `${this.dayString}, ${this.day.date()} ${this.month} ${this.day.year()} ${this.$t('shared.from-to', { from: this.day.format('HH:mm'), to: this.dayTo.format('HH:mm') })}`
    },
    paymentStatus() {
      const paymentStatus = { paid: 0, total: 0 }

      Object.values(this.event.calendarEventCustomers).forEach(calendarEventCustomer => {
        paymentStatus.total += calendarEventCustomer.settlement.amount
        if (calendarEventCustomer.settlement.paid) {
          paymentStatus.paid += calendarEventCustomer.settlement.amount
        }
      })

      return paymentStatus
    },
  },
  methods: {
    dayjs,
  },
}
</script>
