import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import role from '@/auth/role'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'

export default () => {
  const pastCalendarEvents = ref([])
  const futureCalendarEvents = ref([])
  const documentsToAccept = ref([])

  store.dispatch('customers/fetchCustomerCalendarEvents', {
    params: {
      limit: 3,
      orderDir: 'desc',
      to: dayjs()
        .format(),
    },
  })
    .then(res => {
      pastCalendarEvents.value = res.data.items
    })
    .catch(err => parseRequestError(err))

  store.dispatch('customers/fetchCustomerDocumentsToAccept')
    .then(res => {
      documentsToAccept.value = res.data
    })
    .catch(err => parseRequestError(err))

  store.dispatch('customers/fetchCustomerCalendarEvents', {
    params: {
      limit: 3,
      from: dayjs()
        .format(),
    },
  })
    .then(res => {
      futureCalendarEvents.value = res.data.items
    })
    .catch(err => parseRequestError(err))

  const acceptDocument = documentId => {
    store.dispatch('customers/acceptCustomerDocument', documentId)
      .then(() => {
        documentsToAccept.value = documentsToAccept.value.filter(doc => doc.id !== documentId)
      })
      .catch(err => parseRequestError(err))
  }

  const declineDocument = documentId => {
    store.dispatch('customers/declineCustomerDocument', documentId)
      .then(() => {
        documentsToAccept.value = documentsToAccept.value.filter(doc => doc.id !== documentId)
      })
      .catch(err => parseRequestError(err))
  }

  return {
    pastCalendarEvents,
    futureCalendarEvents,
    documentsToAccept,
    acceptDocument,
    declineDocument,
    role,
    dayjs,
  }
}
